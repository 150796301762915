import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { FilterContextProvider } from './context/FilterContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
  <FilterContextProvider>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </FilterContextProvider>
  </AuthContextProvider>
);

